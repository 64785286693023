<template>
  <form class="has-sticky-submit" @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          {{ titleDescription }}
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.periodic-discounts.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="form.title"
                  class="input"
                  type="text"
                  name="title"
                />
                <p v-show="errors.has('title')" class="help is-danger">
                  Εισάγετε τίτλο
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ποσοστό Έκπτωσης *</label>
              <div class="control">
                <input
                  v-validate="'required|min_value:0'"
                  v-model.trim.number="form.discount"
                  class="input"
                  type="number"
                  min="0"
                  step="0.01"
                  name="discount"
                />
                <p v-show="errors.has('discount')" class="help is-danger">
                  Εισάγετε ποσοστό έκπτωσης
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="form.active" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Sold Limit *</label>
              <div class="control">
                <input
                  v-validate="'required|excluded:0'"
                  v-model.trim.number="form.sold_limit"
                  class="input"
                  type="number"
                  name="sold_limit"
                  min="0"
                  step="1"
                />
                <p v-show="errors.has('sold_limit')" class="help is-danger">
                  Εισάγετε Τεμάχια
                </p>
              </div>
            </div>
          </div>
          <div class="column is-full">
            <div class="field">
              <label class="label">
                Ημερομηνία Εκκίνησης *
              </label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <Datepicker
                    v-validate="'required'"
                    v-model="form.date_start"
                    :disabled-dates="disabledDates"
                    :open-date="new Date()"
                    name="date_start"
                    format="dd-MM-yyyy"
                    language="el"
                    wrapper-class="field"
                    input-class="input"
                    placeholder="πχ: 02-12-2017"
                  />
                  <p v-show="errors.has('date_start')" class="help is-danger">
                    Εισάγετε ημ/νία εκκίνησης
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <div class="field">
              <label class="label">
                Ημερομηνία Λήξης *
              </label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <datepicker
                    v-validate="'required'"
                    :disabled-date="disableDate"
                    v-model="form.date_end"
                    :open-date="new Date()"
                    name="date_end"
                    format="dd-MM-yyyy"
                    language="el"
                    wrapper-class="field"
                    input-class="input"
                    placeholder="πχ: 02-12-2017"
                  />
                  <p v-show="errors.has('date_start')" class="help is-danger">
                    Εισάγετε ημ/νία λήξης
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <StickyFormFooter>
      <template v-slot:left>
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </template>
    </StickyFormFooter>
  </form>
</template>

<script>
import { pick } from 'lodash';
import AddItem from '@/views/components/AddItem';
import request from '@/utils/request';
import ProductsSearch from '@/views/components/ProductsSearch';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    ProductsSearch,
    StickyFormFooter,
    Datepicker,
  },

  extends: AddItem,

  data() {
    return {
      periodic_discount: {},
      form: {},
      isLoadingPhotos: false,
      disabledDates: new Date(),
    };
  },

  computed: {
    titleDescription() {
      // eslint-disable-next-line camelcase
      const { periodic_discount } = this;
      /* eslint-disable */ if (periodic_discount.active === true) {
        return 'Ενεργή Περιοδική Έκπτωση';
      } else if (periodic_discount.active === false) {
        return 'Μη Ενεργή Περιοδική Έκπτωση';
      } else {
        return 'Περιοδική Έκπτωση';
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(
        `/periodic-discounts/${to.params.uuid}`,
      );

      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push({ name: 'error' }));
    }
  },

  methods: {
    disableDate(date) {
      if (
        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') <
        moment()
          .add(2, 'days')
          .format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    },

    setData(data) {
      this.periodic_discount = {
        ...data.periodicDiscount,
      };

      this.form = pick(this.periodic_discount, [
        'title',
        'active',
        'sold_limit',
        'discount',
        'date_start',
        'date_end',
      ]);
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        const { data } = await request.put(
          `/periodic-discounts/${this.periodic_discount.id}`,
          {
            ...this.form,
          },
        );
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η Περιοδική Έκπτωση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = pick(this.periodic_discount, [
        'title',
        'active',
        'sold_limit',
        'discount',
        'date_start',
        'date_end',
      ]);

      this.$validator.reset();
    },
  },
};
</script>
