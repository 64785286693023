<template>
  <tr :class="{ 'is-selected': selected, 'hidden-print': !selected }">
    <td class="hidden-print has-text-centered">
      <checkbox v-model="selected" name="selected" />
    </td>
    <td class="hidden-print has-text-centered">
      <div class="photo">
        <Photo
          :photo="{ public_id: item.public_id }"
          :width="150"
          :height="150"
        />
      </div>
    </td>
    <td class="has-text-centered">
      {{ item.url }}
    </td>
    <td class="has-text-centered">
      {{ item.date_start | timestamp('YYYY-MM-DD', 'DD-MM-YYYY') }}
    </td>
    <td class="has-text-centered">
      {{ item.date_end | timestamp('YYYY-MM-DD', 'DD-MM-YYYY') }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      {{ item.title }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      {{ item.description }}
    </td>

    <td class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        @change="onChange"
      />
    </td>

    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>

    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <RouterLink
          :to="{
            name: 'marketing.giftActions.giftActions.edit',
            params: { id: item.id },
          }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </RouterLink>
        <button class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';
import Photo from '@/views/components/Photo';

export default {
  components: {
    Photo,
  },

  extends: PaginatedListItem,
};
</script>
