<template>
  <div class="card">
    <div
      :style="{ backgroundImage: `url(${photo.path})` }"
      class="card-content"
    >
      <span
        v-tooltip="'Αρχική Φωτογραφία'"
        v-if="photo.order === 0"
        class="icon is-small star"
      >
        <i class="fa fa-star" />
      </span>
      <span v-if="photo.is_avatar" class="type">Avatar</span>
      <span v-if="photo.is_banner" class="type">Banner</span>
    </div>
    <div class="card-footer">
      <div class="field has-addons is-marginless">
        <div class="control">
          <button
            v-tooltip="'Προβολή'"
            class="button is-fullwidth"
            type="button"
            @click="selectPhotoToPreview"
          >
            <span class="icon is-small">
              <i class="fa fa-eye" />
            </span>
          </button>
        </div>
        <div v-if="!shouldHideEdit" class="control">
          <button
            v-tooltip="'Επεξεργασία'"
            class="button is-fullwidth"
            type="button"
            @click="selectPhotoToEdit"
          >
            <span class="icon is-small">
              <i class="fa fa-pencil" />
            </span>
          </button>
        </div>
        <div v-if="!shouldHideDelete" class="control">
          <button
            v-tooltip="'Διαγραφή'"
            class="button is-fullwidth"
            type="button"
            @click="selectPhotoToDelete"
          >
            <span class="icon is-small">
              <i class="fa fa-trash" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photo: Object,
    canEditAvatar: Boolean,
    canDeleteAvatar: Boolean,
  },
  computed: {
    shouldHideEdit() {
      return this.photo.is_avatar && this.canEditAvatar === false;
    },
    shouldHideDelete() {
      return this.photo.is_avatar && this.canDeleteAvatar === false;
    },
  },
  methods: {
    selectPhotoToPreview() {
      this.$emit('selectPhotoToPreview', this.photo);
    },
    selectPhotoToEdit() {
      this.$emit('selectPhotoToEdit', this.photo);
    },
    selectPhotoToDelete() {
      this.$emit('selectPhotoToDelete', this.photo);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px 4px 0 0;
    flex: 1;
    padding: 0;
    position: relative;

    .type {
      background-color: #22a684;
      border-radius: 0 4px 0 4px;
      color: #ffffff;
      font-weight: 700;
      padding: 5px 10px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .star {
      background-color: #22a684;
      border-radius: 4px 0 4px 0;
      box-sizing: content-box;
      color: #fff;
      display: inline-block;
      font-weight: 700;
      height: 19px;
      left: 0;
      line-height: 19px;
      padding: 5px 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-footer {
    border: none;
    padding: 0;

    .button {
      border: none;
    }
  }

  &:hover {
    cursor: move;
  }
}

.field {
  width: 100%;
}

.control {
  flex: 1;
}
</style>
