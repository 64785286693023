<template>
  <tr :class="{ 'is-selected': selected, 'hidden-print': !selected }">
    <td class="hidden-print has-text-centered">
      <checkbox v-model="selected" name="selected" />
    </td>
    <td class="hidden-print has-text-centered">
      <ProductPhoto :type="item.type" :photos="item.photos" />
    </td>
    <td class="has-text-centered">
      <p v-for="(barcode, index) in item.barcodes" :key="index">
        {{ barcode.code }}
      </p>
    </td>
    <td class="has-text-centered">
      {{ item.common_id }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <RouterLink :to="{ name: 'products.edit', params: { uuid: item.uuid } }">
        {{ item.title }}
      </RouterLink>
    </td>
    <td class="has-text-centered">
      {{ item.stock }}
    </td>
    <td class="has-text-centered">
      {{ item.sell_price }}
    </td>
    <td class="has-text-centered">
      {{ item.discount }}
    </td>
    <td class="has-text-centered">
      {{ item.final_price }}
    </td>
    <td class="has-text-centered">
      <span class="button is-small is-static">{{ item.views }}</span>
    </td>
    <td class="has-text-centered">
      <span class="button is-small is-static">{{ item.purchases }}</span>
    </td>
    <td v-if="!isDeleted" class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <RouterLink
          :to="{ name: 'products.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </RouterLink>
        <button v-if="!isDeleted" class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
        <button v-else class="button is-small" @click="onRestore">
          <span class="icon is-small">
            <span class="fa fa-fw fa-repeat" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },
  extends: PaginatedListItem,
  computed: {
    hasAvatar() {
      if (!this.item.photos.length) {
        return false;
      }

      /* eslint-disable camelcase */
      return (
        this.item.photos.find(({ is_avatar }) => is_avatar === true) !==
        undefined
      );
    },
  },
};
</script>
