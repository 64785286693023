<template>
  <form @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          Περιοδικές Εκπτώσεις
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.periodic-discounts.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="newPeriodicDiscount.title"
                  class="input"
                  type="text"
                  name="title"
                />
                <p v-show="errors.has('title')" class="help is-danger">
                  Εισάγετε τίτλο
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ποσοστό Έκπτωσης *</label>
              <div class="control">
                <input
                  v-validate="'required|min_value:0'"
                  v-model.trim.number="newPeriodicDiscount.discount"
                  class="input"
                  type="number"
                  min="0"
                  step="0.01"
                  name="discount"
                />
                <p v-show="errors.has('discount')" class="help is-danger">
                  Εισάγετε ποσοστό έκπτωσης
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select
                    v-model.number="newPeriodicDiscount.active"
                    name="active"
                  >
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Sold Limit *</label>
              <div class="control">
                <input
                  v-validate="'required|excluded:0'"
                  v-model.trim.number="newPeriodicDiscount.sold_limit"
                  class="input"
                  type="number"
                  name="sold_limit"
                  min="0"
                  step="1"
                />
                <p v-show="errors.has('sold_limit')" class="help is-danger">
                  Εισάγετε Τεμάχια
                </p>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <label class="label">
              Ημερομηνία Εκκίνησης *
            </label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <Datepicker
                  v-validate="'required'"
                  v-model="newPeriodicDiscount.date_start"
                  :disabled-dates="disabledDates"
                  :open-date="new Date()"
                  name="date_start"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
                <p v-show="errors.has('date_start')" class="help is-danger">
                  Εισάγετε ημ/νία εκκίνησης
                </p>
              </div>
            </div>
          </div>

          <div class="column is-full">
            <label class="label">
              Ημερομηνία Λήξης *
            </label>
            <div class="field has-addons">
              <div class="control is-expanded">
                <datepicker
                  v-validate="'required'"
                  :disabled-date="disableDate"
                  v-model="newPeriodicDiscount.date_end"
                  :open-date="new Date()"
                  name="date_end"
                  format="dd-MM-yyyy"
                  language="el"
                  wrapper-class="field"
                  input-class="input"
                  placeholder="πχ: 02-12-2017"
                />
                <p v-show="errors.has('date_end')" class="help is-danger">
                  Εισάγετε ημ/νία λήξης
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-content">
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import request from '@/utils/request';
import Datepicker from 'vuejs-datepicker';
import AddItem from '@/views/components/AddItem';

import ProductsSearch from '@/views/components/ProductsSearch';

export default {
  components: {
    ProductsSearch,
    Datepicker,
  },

  extends: AddItem,

  data() {
    return {
      newPeriodicDiscount: {
        title: '',
        active: false,
        sold_limit: 0,
        discount: null,
        date_start: moment().toDate(),
        date_end: null,
      },
      disabledDates: new Date(),
    };
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/periodic-discounts', {
          ...this.newPeriodicDiscount,
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router.push({ name: 'marketing.periodicDiscounts.list' });
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η Περιοδική Έκπτωση αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    disableDate(date) {
      if (
        moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') <
        moment()
          .add(2, 'days')
          .format('YYYY-MM-DD')
      ) {
        return true;
      }
      return false;
    },

    reset() {
      this.newPeriodicDiscount = {
        title: '',
        active: false,
        sold_limit: 0,
        discount: null,
        date_start: moment().toDate(),
        date_end: null,
      };

      this.$validator.reset();
    },
  },
};
</script>
