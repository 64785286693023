<template>
  <img :src="path" :width="width" :height="height" />
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
  props: {
    photos: Array,
    photo: Object,
    width: {
      type: Number,
      default: 50,
    },

    height: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    path() {
      let path = 'eshop/logo';

      if (!isEmpty(this.photo)) {
        path = this.photo.public_id;
      }

      if (!isEmpty(this.photos)) {
        // eslint-disable-next-line camelcase
        const avatar = this.photos.find(({ is_avatar }) => is_avatar === true);
        path = avatar ? avatar.public_id : this.photos[0].public_id;
      }

      return this.$cloudinaryUrl(`${path}.jpg`, {
        fetchFormat: 'auto',
        width: this.width,
        height: this.height,
        crop: 'pad',
        background: '#FFF',
      });
    },
  },
};
</script>

<style scoped>
img {
  height: auto;
  width: 100%;
}
</style>
