<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="closeEditModal" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Επεξεργασία Φωτογραφίας</p>
        <button type="button" class="delete" @click="closeEditModal" />
      </header>
      <form @submit.prevent.stop="submit">
        <section class="modal-card-body">
          <label class="label">Slug *</label>
          <div class="field has-addons">
            <div class="control">
              <a class="button">
                {{ prefix }}
              </a>
            </div>
            <div class="control is-expanded">
              <input
                v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                v-model.trim="newPhoto.slug"
                type="text"
                class="input"
                name="slug"
              />
            </div>
            <div class="control">
              <a class="button">
                {{ suffix }}
              </a>
            </div>
          </div>
          <div v-show="errors.has('slug')" class="help is-danger">
            Εισάγετε έγκυρο slug
          </div>

          <div class="field">
            <label class="label">Avatar</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select v-model.number="newPhoto.is_avatar" name="is_avatar">
                  <option :value="false">Όχι</option>
                  <option :value="true">Ναί</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Banner</label>
            <div class="control">
              <div class="select select is-fullwidth">
                <select v-model.number="newPhoto.is_banner" name="is_banner">
                  <option :value="false">Όχι</option>
                  <option :value="true">Ναί</option>
                </select>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import { last, split } from 'lodash';
import FormSubmit from '@/views/components/FormSubmit';

export default {
  components: {
    FormSubmit,
  },

  props: {
    isOpen: Boolean,
    isSaving: Boolean,
    photo: Object,
  },

  data() {
    return {
      newPhoto: {},
      prefix: '',
      suffix: '',
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'newPhoto.is_avatar': function(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.newPhoto.is_banner = false;
      }
    },
    // eslint-disable-next-line func-names
    'newPhoto.is_banner': function(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.newPhoto.is_avatar = false;
      }
    },
  },

  created() {
    const slug = last(split(this.photo.public_id, '/'));
    [this.prefix] = split(this.photo.public_id, slug);
    this.suffix = last(split(slug, '-'));
    this.newPhoto = {
      slug: split(slug, `-${this.suffix}`)[0],
      is_avatar: this.photo.is_avatar,
      is_banner: this.photo.is_banner,
    };
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.$emit('editPhoto', {
          ...this.newPhoto,
          slug: `${this.prefix}${this.newPhoto.slug}-${this.suffix}`,
        });
      } catch (err) {
        console.log(err);
      }
    },

    closeEditModal() {
      this.$emit('closeEditModal');

      this.reset();
    },

    reset() {
      const slug = last(split(this.photo.public_id, '/'));
      [this.prefix] = split(this.photo.public_id, slug);
      this.suffix = last(split(slug, '-'));
      this.newPhoto = {
        slug: split(slug, `-${this.suffix}`)[0],
        is_avatar: this.photo.is_avatar,
        is_banner: this.photo.is_banner,
      };
      this.errors.clear();
    },
  },
};
</script>
