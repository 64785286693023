<template>
  <aside :class="{ 'is-visible': showSidebar }" class="app-sidebar menu">
    <ul class="menu-list">
      <sidebarMenuItem
        v-for="(item, index) in menuItems"
        :item="item"
        :key="index"
      />
    </ul>

    <div>
      <RouterLink to="/versions" class="versions">
        Versions S/N
      </RouterLink>

      <div class="status">
        ERP Status: <strong class="status">{{ status }}</strong>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarMenuItem from './components/SidebarMenuItem';

export default {
  components: {
    SidebarMenuItem,
  },
  props: {
    showSidebar: Boolean,
  },
  computed: {
    ...mapGetters({
      menuItems: 'filteredMenuItems',
      erpStatus: 'getERPStatus',
    }),
    status() {
      return this.erpStatus === true ? 'ON' : 'OFF';
    },
  },
  watch: {
    erpStatus(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === true) {
          this.$notify({
            type: 'success',
            title: 'ERP Status',
            text: 'Η σύνδεση ενεργοποιήθηκε επιτυχώς!!',
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'ERP Status',
            text: 'Η σύνδεση δεν είναι πλέον ενεργή!!',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 3.25rem;
  left: -140px;
  bottom: 0;
  padding: 0 0 1rem;
  width: 140px;
  max-height: 100vh;
  height: calc(100% - 3.25rem);
  z-index: 21;
  background-color: #242424;
  // background-image: linear-gradient(141deg, #22a684 0%, #00d1b2 71%);
  box-shadow: 0 2px 3px hsla(0, 0%, 7%, 0.1), 0 0 0 1px hsla(0, 0%, 7%, 0.1);
  overflow: visible;
  transition: all 0.5s ease-in-out;

  &.is-visible {
    left: 0;
  }

  .versions {
    color: white;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .status {
    font-size: 0.8rem;
    text-align: center;
    color: white;
  }
}
</style>
