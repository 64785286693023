<template>
  <div>
    <form novalidate class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Επεξεργασία Προιόντος
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link :to="{ name: 'products.list' }" class="button">
                  <span class="icon is-small"><i class="fa fa-list"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
              <div class="control">
                <button
                  v-tooltip="'Stock, Info, Barcodes, Κατηγορίες & Φίλτρα Sync'"
                  class="button"
                  type="button"
                  @click="handleERPSync"
                >
                  <span class="icon is-small">
                    <i class="fa fa-cloud-download" />
                  </span>
                  <span>ERP Sync</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-content">
          <ProductsEditMenu />

          <div
            v-if="errorMessages.length"
            class="notification is-danger hidden-print"
          >
            <button class="delete" type="button" @click="errorMessages = []" />
            <ul>
              <li v-for="(error, index) in errorMessages" :key="index">
                <i class="fa fa-check" />
                &nbsp;<strong>{{ error.field }}</strong
                >: {{ error.message }}
              </li>
            </ul>
          </div>

          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">
                  Ονομασία
                  <span v-if="general.title.length" class="tag">{{
                    general.title.length
                  }}</span>
                </label>
                <div class="control">
                  <textarea-autosize
                    v-model.trim="general.title"
                    class="textarea"
                    name="title"
                    rows="1"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">
                  Περιγραφή
                  <span v-if="general.description.length" class="tag">{{
                    general.description.length
                  }}</span>
                </label>
                <div class="control">
                  <textarea-autosize
                    v-model.trim="general.description"
                    class="textarea"
                    name="description"
                    rows="1"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Περιεχόμενο</label>
                <div class="control">
                  <VueEditor
                    id="editor-1"
                    v-model="general.content"
                    :editor-toolbar="toolbarOptions"
                    data-vv-value-path="value"
                    data-vv-name="content"
                    @input="changeContent('content', $event)"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Συστατικά</label>
                <div class="control">
                  <VueEditor
                    id="editor-2"
                    :editor-toolbar="toolbarOptions"
                    v-model="general.ingredients"
                    @input="changeContent('ingredients', $event)"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Marketing Description</label>
                <div class="control">
                  <textarea-autosize
                    v-model="general.marketing_description"
                    class="textarea"
                    name="marketing Description"
                    rows="1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Εμφανές *</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="general.active"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    :disabled="isActiveDisabled"
                    @change="updateActive"
                  />
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Βάρος</label>
                <div class="control">
                  <input
                    v-model.trim.number="general.weight"
                    class="input"
                    type="number"
                    name="weight"
                    min="0"
                    step="0.001"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard
        v-if="isProduct || isGift"
        :is-open="true"
        title="Επιλογή Τύπου"
      >
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Στην περίπτωση που ένα product
              καθοριστεί ως <strong>Gift</strong> τότε αυτό δεν
              συμπεριλαμβάνεται στις λίστες αναζήτησης
              <strong>Algolia</strong> & <strong>Search Product</strong>
            </li>
          </ul>
        </template>
        <div class="card-content">
          <div class="is-full">
            <div class="field">
              <label class="label">Τύπος</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select
                    v-model="general.type"
                    :value="general.type"
                    name="type"
                  >
                    <option value="product">Product</option>
                    <option value="gift">Gift</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="SEO Title - Slug">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Το πεδίο
              <strong>SEO Title</strong> έρχεται απο το ERP μόνο για κάποια
              products κι έχει προτεραιότητα έναντι της
              <strong>Ονομασίας</strong> στη δημιουργία του πεδίου
              <strong>Slug</strong>
            </li>
          </ul>
        </template>
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-half is-full-desktop">
              <div class="field">
                <label class="label">
                  SEO Title
                  <span v-if="general.seo_title" class="tag">{{
                    general.seo_title.length
                  }}</span>
                </label>
                <div class="control">
                  <input
                    :disabled="isSeoTitleDisabled"
                    v-model.trim="general.seo_title"
                    class="input"
                    type="text"
                    name="seo_title"
                  />
                </div>
              </div>
            </div>

            <div class="column is-half is-full-desktop">
              <div class="field">
                <label class="label">
                  Slug
                  <span v-if="general.slug" class="tag">{{
                    general.slug.length
                  }}</span>
                </label>
                <div class="control">
                  <input
                    v-validate="'regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                    :disabled="!isSuper"
                    v-model.trim="general.slug"
                    class="input"
                    type="text"
                    name="slug"
                  />
                  <p v-show="errors.has('slug')" class="help is-danger">
                    Εισάγετε έγκυρο slug
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Επιλογές Διαθεσιμότητας">
        <template v-slot:info v-if="isProduct || isGift">
          <ul>
            <li>
              <i class="fa fa-check" /> Στα products το <strong>Stock</strong>
              δεν μπορεί να μεταβληθεί απο το χρήστη καθώς έρχεται αυτόματα απο
              το ERP
            </li>
            <li>
              <i class="fa fa-check" /> Η ενημέρωση του <strong>Stock</strong>
              απο το ERP γίνεται ανα τακτά χρονικά διαστήματα (ανά 5 λεπτά)
            </li>
          </ul>
        </template>
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column">
              <div class="field">
                <label class="label">Διαθεσιμότητα *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model.trim.number="general.availability_id"
                      name="availability_id"
                    >
                      <option
                        v-for="item in availabilities"
                        :key="item.id"
                        :value="item.id"
                        :disabled="
                          general.available_forever === true && item.id === 6
                        "
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Μόνιμα διαθέσιμο</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="general.available_forever === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateAvailableForever"
                  />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Stock *</label>
                <div class="control">
                  <input
                    v-model.trim.number="general.stock"
                    class="input"
                    type="number"
                    disabled
                    name="stock"
                    min="0"
                    step="0.001"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard
        v-if="isProduct"
        :is-open="true"
        title="Επιλογές XML Feed"
      >
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Στην περίπτωση που ένα product δεν είναι
              εμφανές τότε δεν συμπεριλαμβάνεται στις XML λίστες των
              <strong>Skroutz</strong>
              & <strong>Best Price</strong> παρά τις όποιες ρυθμίσεις ανα λίστα
            </li>
            <li>
              <i class="fa fa-check" /> Στην περίπτωση που ένα product δεν είναι
              εμφανές οι πρότερες επιλογές παραμένουν αποθηκευμένες ενώ δεν
              επιτρέπεται η επεξεργασία τους κατα το διάστημα αυτό. Μόλις το
              product γίνει πάλι εμφανές τότε οι επιλογές αυτές έχουν αυτούσιες
              άμεση ισχύ
            </li>
            <li>
              <i class="fa fa-check" /> H επεξεργασία των επιλογών επιτρέπεται
              μόνο όταν το product είναι εμφανές
            </li>
          </ul>
        </template>
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Skroutz</label>
                <div class="control">
                  <ToggleButton
                    :disabled="isActiveDisabled"
                    :sync="true"
                    :value="general.skroutz === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateFeed($event, 'skroutz')"
                  />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Best Price</label>
                <div class="control">
                  <ToggleButton
                    :disabled="isActiveDisabled"
                    :sync="true"
                    :value="general.best_price === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateFeed($event, 'best_price')"
                  />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Contact Pigeon</label>
                <div class="control">
                  <ToggleButton
                    :disabled="isActiveDisabled"
                    :sync="true"
                    :value="general.contact_pigeon === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateFeed($event, 'contact_pigeon')"
                  />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Facebook</label>
                <div class="control">
                  <ToggleButton
                    :disabled="isActiveDisabled"
                    :sync="true"
                    :value="general.facebook === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateFeed($event, 'facebook')"
                  />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Google</label>
                <div class="control">
                  <ToggleButton
                    :disabled="isActiveDisabled"
                    :sync="true"
                    :value="general.google === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateFeed($event, 'google')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <edit-barcodes
        v-if="isProduct || isGroup || isGift"
        :type="model.type"
        :barcodes="barcodes"
        :photos="avatarPhotos"
        :colors="colors"
        :sizes="sizes"
        @updateBarcodes="handleUpdateBarcodes"
        @on-update-barcode-photo="handleUpdateBarcodePhoto"
      />

      <ToggleableCard :is-open="true" title="Νέο προιόν - Ημ/νία Λήξης">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Στην περίπτωση που χαρακτηρίσετε ένα
              προιόν ώς νέο, τότε αυτόματα τίθεται ημ/νια αναίρεσης του
              χαρακτηρισμού αυτού σε 3 μήνες. Την ημ/νια αυτή μπορείτε να τη
              μεταβάλλεται αμέσως μετά κατά βούληση
            </li>
          </ul>
        </template>
        <div class="card-content">
          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <label class="label">Νέο προιόν</label>
                <div class="control">
                  <ToggleButton
                    :sync="true"
                    :value="general.is_new === true"
                    :color="{ checked: '#22A684', unchecked: '#ff3860' }"
                    :width="30"
                    :height="15"
                    @change="updateIsNew"
                  />
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Ημ/νία Λήξης</label>
                <div class="control">
                  <Datepicker
                    v-model="general.new_expires_at"
                    name="published_at"
                    format="dd-MM-yyyy"
                    language="el"
                    wrapper-class="field"
                    input-class="input"
                    placeholder="πχ: 02-12-2017"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <edit-photos
        :model="model"
        :photos="photos"
        :is-loading="isLoadingPhotos"
        @updatePhotos="updatePhotos"
      />

      <ToggleableCard v-if="isSet" :is-open="true" title="Set Items *">
        <template v-slot:info>
          <ul>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των set items θα
              μεταβάλλει το συνολικό <strong>Βάρος</strong> αυτόματα
            </li>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των set items θα
              μεταβάλλει την <strong>Π.Λ. Τιμή Πώλησης</strong> και την
              <strong>Τελική Τιμή</strong> αυτόματα
            </li>
          </ul>
        </template>
        <div class="card-content">
          <div class="table-wrapper">
            <table
              v-if="general.subsets.length"
              class="table is-bordered is-striped is-fullwidth"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Φώτο</th>
                  <th>Barcodes</th>
                  <th>Common ID</th>
                  <th>Τίτλος</th>
                  <th>Π.Λ. Τιμή (&euro;)</th>
                  <th>Έκπτωση (&#x25;)</th>
                  <th>Τελική Τιμή (&euro;)</th>
                  <th>Προβολή</th>
                  <th>Ποσότητα</th>
                  <th>Βάρος<br />(ανά προιόν)</th>
                  <th>Ενέργειες</th>
                </tr>
              </thead>
              <tbody>
                <subset
                  v-for="(item, index) in general.subsets"
                  :item="item"
                  :index="index"
                  :key="item.id"
                  @onDelete="onDeleteSubset"
                  @onChangeCount="onChangeSubsetCount"
                />
              </tbody>
            </table>
          </div>

          <subsets-search :set="general" @selectProduct="onSelectSubset" />
          <div v-show="errors.has('subsets')" class="help is-danger">
            Επιλέξτε τουλάχιστον 1 προιόν
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard v-if="isGroup" :is-open="true" title="Group Items">
        <div class="card-content">
          <div class="table-wrapper">
            <table
              v-if="general.subgroups.length"
              class="table is-bordered is-striped is-fullwidth"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Φώτο</th>
                  <th>Barcodes</th>
                  <th>Common ID</th>
                  <th>Τίτλος</th>
                  <th>Χρώμα</th>
                  <th>Μέγεθος</th>
                  <th>Stock</th>
                  <th>Π.Λ. Τιμή (&euro;)</th>
                  <th>Έκπτωση (&#x25;)</th>
                  <th>Τελική Τιμή (&euro;)</th>
                  <th>Προβολή</th>
                  <th>Ενέργειες</th>
                </tr>
              </thead>
              <tbody>
                <subgroup
                  v-for="(item, index) in general.subgroups"
                  :item="item"
                  :index="index"
                  :key="item.id"
                  :colors="colors"
                  :sizes="sizes"
                  @onDelete="onDeleteSubgroup"
                  @onChangeColor="onChangeColor"
                  @onChangeSize="onChangeSize"
                />
              </tbody>
            </table>
          </div>

          <subgroups-search
            :group="general"
            @selectProduct="onSelectSubgroup"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Τιμολογιακή Πολιτική">
        <template v-slot:info v-if="isProduct || isGift">
          <ul>
            <li>
              <i class="fa fa-check" /> Στα products τα πεδία
              <strong>Τιμή Αγοράς</strong>,
              <strong>Π.Λ. Τιμή Πώλησης</strong> και <strong>ΦΠΑ</strong> δεν
              μπορούν να μεταβληθούν άμεσα απο το χρήστη διότι οι τιμές τους
              έρχονται απο το ERP
            </li>
          </ul>
        </template>
        <template v-slot:info v-if="isSet">
          <ul>
            <li>
              <i class="fa fa-check" /> Τυχόν μεταβολή των set items θα
              μεταβάλλει την <strong>Π.Λ. Τιμή Πώλησης</strong> και την
              <strong>Τελική Τιμή</strong> αυτόματα
            </li>
            <li>
              <i class="fa fa-check" /> Στα sets η
              <strong>Τελική Τιμή</strong> δεν μπορεί να μεταβληθεί άμεσα απο το
              χρήστη. Προτιμήστε την αλλαγή του
              <strong>Ποσοστού Έκπτωσης</strong>
            </li>
          </ul>
        </template>
        <div class="card-content">
          <EditPrice
            :type="model.type"
            :product="general"
            @updatePrice="updatePrice"
          >
            <div class="column is-half">
              <div class="field">
                <label class="label">ΦΠΑ *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      :disabled="isProduct || isGift"
                      v-model.trim.number="general.tax_id"
                      name="tax_id"
                    >
                      <option
                        v-for="item in taxes"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.percentage }} %
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </EditPrice>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Κατηγορίες">
        <div class="card-content">
          <EditCategories
            :categories="categories"
            @updateCategories="updateCategories"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard
        v-if="isProduct || isGroup || isGift"
        :is-open="true"
        title="Brand & Collection"
      >
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Brand *</label>
              <brands-search
                :brand="general.brand"
                @selectBrand="selectBrand"
                @removeBrand="removeBrand"
              />
              <p v-show="errors.has('brand')" class="help is-danger">
                Επιλέξτε brand
              </p>
            </div>

            <div v-if="general.brand_id" class="column is-full">
              <label class="label">Collection</label>
              <multiselect
                v-model="general.collection"
                :disabled="isLoadingCollections || !general.brand_id"
                :options="collections"
                name="collections"
                track-by="id"
                label="title"
                placeholder="Επιλέξτε collection"
                selected-label="Επιλεγμένο"
                select-label="Πατήστε enter για επιλογή"
                deselect-label="Πατήστε enter για απο-επιλογή"
              />
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Συμπτώματα & Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <multiselect
                v-model="general.symptoms"
                :options="symptoms"
                :multiple="true"
                name="symptoms"
                class="multiple"
                track-by="id"
                label="title"
                placeholder="Επιλέξτε symptoms"
                selected-label="Επιλεγμένο"
                select-label="Πατήστε enter για επιλογή"
                deselect-label="Πατήστε enter για απο-επιλογή"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Promos">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <div class="control">
                  <multiselect
                    v-model="general.promos"
                    :options="promos"
                    :multiple="true"
                    name="promos"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε Marketing Page"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Φίλτρα">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">FilterGroups</label>
                <div class="control">
                  <multiselect
                    :value="general.filterGroups"
                    :options="filterGroups"
                    :multiple="true"
                    name="filterGroups"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε filterGroups"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                    @select="selectFilterGroup"
                    @remove="removeFilterGroup"
                  />
                </div>
              </div>
            </div>
            <div
              v-for="(filterGroup, index) in general.filterGroups"
              :key="filterGroup.id"
              class="column is-full"
            >
              <div class="field">
                <label class="label">
                  FilterGroup: {{ filterGroup.title }}
                </label>
                <div class="control">
                  <multiselect
                    :value="filterGroup.selections"
                    :options="filterGroup.filters"
                    :multiple="true"
                    :name="`filterGroups[${index}].filters`"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε filters"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                    @select="selectFilter"
                    @remove="removeFilter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard
        v-if="general.bundles.length"
        :is-open="true"
        title="Bundles"
      >
        <div class="card-content">
          <div class="table-wrapper">
            <table
              class="table is-bordered is-striped is-marginless is-fullwidth"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Προιόντα</th>
                  <th>Έκπτωση (&#x25;)</th>
                  <th>Ημ/νία Λήξης</th>
                  <th>Κατάσταση</th>
                  <th>Ενέργειες</th>
                </tr>
              </thead>
              <tbody>
                <bundle
                  v-for="(item, index) in general.bundles"
                  :model="model"
                  :item="item"
                  :index="index"
                  :key="item.id"
                />
              </tbody>
            </table>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard v-if="general.sets.length" :is-open="true" title="Sets">
        <div class="card-content">
          <div class="table-wrapper">
            <table class="table is-bordered is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Φώτο</th>
                  <th>Barcodes</th>
                  <th>Common ID</th>
                  <th>Τίτλος</th>
                  <th>Π.Λ. Τιμή (&euro;)</th>
                  <th>Έκπτωση (&#x25;)</th>
                  <th>Τελική Τιμή (&euro;)</th>
                  <th>Προβολή</th>
                  <th>Βάρος</th>
                  <th>Ενέργειες</th>
                </tr>
              </thead>
              <tbody>
                <set
                  v-for="(item, index) in general.sets"
                  :item="item"
                  :index="index"
                  :key="item.id"
                />
              </tbody>
            </table>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard
        v-if="general.groups.length"
        :is-open="true"
        title="Groups"
      >
        <div class="card-content">
          <div class="table-wrapper">
            <table class="table is-bordered is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Φώτο</th>
                  <th>Common ID</th>
                  <th>Τίτλος</th>
                  <th>Π.Λ. Τιμή (&euro;)</th>
                  <th>Έκπτωση (&#x25;)</th>
                  <th>Τελική Τιμή (&euro;)</th>
                  <th>Προβολή</th>
                  <th>Βάρος</th>
                  <th>Ενέργειες</th>
                </tr>
              </thead>
              <tbody>
                <group
                  v-for="(item, index) in general.groups"
                  :item="item"
                  :index="index"
                  :key="item.id"
                />
              </tbody>
            </table>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Ανακατεύθυνση (301)">
        <div class="card-content">
          <EditRedirectionDetails
            :redirection="redirection"
            @updateRedirection="updateRedirection"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Σχόλια">
        <div class="card-content">
          <div v-if="model.reviews.length" class="table-wrapper">
            <table class="table is-bordered is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>#</th>
                  <th class="hidden-print">Σχόλιο</th>
                  <th class="hidden-print">Προβολή</th>
                </tr>
              </thead>
              <tbody>
                <Comment
                  v-for="(item, index) in model.reviews"
                  :item="item"
                  :index="index"
                  :key="item.id"
                />
              </tbody>
            </table>
          </div>
          <h4 v-else class="is-3 has-text-centered">Δε βρέθηκαν σχόλια</h4>
        </div>
      </ToggleableCard>

      <ToggleableCard v-if="meta" :is-open="true" title="SEO">
        <div class="card-content">
          <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
        </div>
      </ToggleableCard>

      <ToggleableCard v-if="socialMeta" :is-open="true" title="Social Meta">
        <div class="card-content">
          <EditSocialMetaDetails
            :social-meta="socialMeta"
            :is-read-only="false"
            @updateSocialMeta="updateSocialMeta"
          />
        </div>
      </ToggleableCard>

      <StickyFormFooter>
        <template v-slot:left>
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </template>
        <template v-slot:right>
          <a
            v-tooltip="'Προβολή στο e-shop'"
            :href="model.path"
            target="_blank"
          >
            {{ model.title }} <i class="fa fa-arrow-right" />
          </a>
        </template>
      </StickyFormFooter>
    </form>

    <confirmation
      :model-name="model.title"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του προιόντος;"
      @closeModal="closeModal"
    />

    <SyncModal :is-open="isSyncing" />
  </div>
</template>

<script>
import slug from 'slug';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { mapGetters, mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import {
  clone,
  cloneWith,
  flow,
  get,
  isEqual,
  pick,
  round,
  sortBy,
  uniq,
} from 'lodash';
import request from '@/utils/request';
import EditItem from '@/views/components/EditItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import EditRedirectionDetails from '@/views/components/EditRedirectionDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import EditPhotos from '@/views/components/EditPhotos';
import EditPrice from '@/views/components/EditPrice';
import SyncModal from '@/views/components/SyncModal';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import toolbarOptions from '@/constants/toolbarOptions';
import Comment from '../components/Comment';
import SubsetsSearch from '../components/SubsetsSearch';
import SubgroupsSearch from '../components/SubgroupsSearch';
import Subset from '../components/Subset';
import Subgroup from '../components/Subgroup';
import Bundle from '../components/Bundle';
import Set from '../components/Set';
import Group from '../components/Group';
import EditCategories from '../../../components/EditCategories';
import ProductsEditMenu from '../components/ProductsEditMenu';
import EditBarcodes from '../components/EditBarcodes';
import BrandsSearch from '../../../components/BrandsSearch';
import CollectionsBrandsSearch from '../../../components/CollectionsBrandsSearch';
import SymptomsSearch from '../../../components/SymptomsSearch';
import FiltersSearch from '../../../components/FiltersSearch';

export default {
  components: {
    VueEditor,
    Datepicker,
    Multiselect,
    ToggleableCard,
    EditRedirectionDetails,
    EditMetaDetails,
    EditSocialMetaDetails,
    EditPhotos,
    EditCategories,
    EditPrice,
    SyncModal,
    StickyFormFooter,
    ProductsEditMenu,
    EditBarcodes,
    BrandsSearch,
    CollectionsBrandsSearch,
    SymptomsSearch,
    FiltersSearch,
    Comment,
    SubsetsSearch,
    SubgroupsSearch,
    Subset,
    Subgroup,
    Bundle,
    Set,
    Group,
  },

  extends: EditItem,

  data() {
    return {
      errorMessages: [],
      categories: [],
      redirection: {},
      meta: {},
      socialMeta: {},
      general: {},
      defaultTax: 1,
      barcodes: [],
      photos: [],
      subsets: [],
      subgroups: [],
      isSyncing: false,
      isLoadingPhotos: false,
      toolbarOptions,
      isActiveDisabled: false,
    };
  },

  computed: {
    ...mapGetters({
      isSuper: 'getIsSuper',
      model: 'products/edit/getProduct',
      generalModel: 'general/edit/getGeneral',
      availabilities: 'getAvailabilities',
      taxes: 'taxes/list/getTaxes',
      tags: 'tags/all/getTags',
      promos: 'promos/all/getPromos',
      categoriesReverseTree: 'categories/list/getCategoriesReverseTree',
      categoriesPerType: 'categories/list/getCategoriesPerType',
      colors: 'colors/all/getColors',
      sizes: 'sizes/all/getSizes',
      filterGroups: 'filterGroups/all/getFilterGroups',
      symptoms: 'symptoms/all/getSymptoms',
      collections: 'collections/all/getCollections',
      isLoadingCollections: 'collections/all/getIsLoading',
    }),

    isSeoTitleDisabled() {
      return !this.isSuper || this.isSet;
    },

    isProduct() {
      return this.model.type === 'product';
    },

    isGift() {
      return this.model.type === 'gift';
    },

    isSet() {
      return this.model.type === 'set';
    },

    isGroup() {
      return this.model.type === 'group';
    },

    avatarPhotos() {
      return this.photos.filter(photo => photo.is_banner === false);
    },
  },

  watch: {
    model: {
      handler(newVal, oldVal) {
        // Update info only when changing model
        if (newVal.id !== oldVal.id) {
          this.setProduct(newVal);
        }

        // Update photos always since this functions independently
        this.photos = clone(this.model.photos);
        this.barcodes = clone(this.model.barcodes);
      },
      deep: true,
    },

    general: {
      handler(newVal) {
        /* eslint-disable */
        let isActiveDisabled = false;
        const { type, title, seo_title, slug, sell_price, weight } = newVal;
        const photos = get(this.model, 'photos', []);
        const subsets = get(this.general, 'subsets', []);

        if (
          !title ||
          (this.isProduct && !seo_title) ||
          !slug ||
          !sell_price ||
          !weight ||
          !photos.some(({ is_avatar }) => is_avatar === true)
        ) {
          isActiveDisabled = true;
        }

        if (type === 'set' && !subsets.length) {
          isActiveDisabled = true;
        }

        this.isActiveDisabled = isActiveDisabled;
      },
      deep: true,
    },

    isActiveDisabled(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.general = {
          ...this.general,
          active: false,
        };
      }
    },

    'general.brand': {
      handler(newVal, oldVal) {
        if (
          get(newVal, 'uuid') !== get(oldVal, 'uuid') &&
          get(oldVal, 'uuid')
        ) {
          this.getCollections({ uuid: newVal.uuid });
        }
      },
      immediate: true,
    },

    'general.seo_title': function(newVal, oldVal) {
      // When seo_title is already set and a super admin changes it
      // then we need to update slugs, urls and titles in various places
      if (oldVal !== undefined && newVal !== oldVal && !this.isSet) {
        const newSlug = slug(newVal.toLowerCase());
        this.general.slug = newSlug;
        const schema = JSON.parse(this.meta.schema);
        const description = `Βρες αναλυτικές πληροφορίες για ${newVal}. Διάβασε χαρακτηριστικά και τρόπο χρήσης για το προϊόν ή ρώτησε τους φαρμακοποιούς μας σχετικά με το ${newVal}.`;

        schema.url = `https://carespot.gr/${this.model.brand.slug}/${newSlug}`;
        schema.name = newVal;

        this.meta.schema = JSON.stringify(schema, 0, 2);
        this.meta.title = `${newVal} | Carespot.gr`;
        this.meta.description = description;
        this.meta.keywords = newVal;
        this.socialMeta.og_url = `https://carespot.gr/${this.model.brand.slug}/${newSlug}`;
        this.socialMeta.og_title = `${newVal} | Carespot.gr`;
        this.socialMeta.og_description = description;
        this.socialMeta.twitter_title = `${newVal} | Carespot.gr`;
        this.socialMeta.twitter_description = description;
        this.redirection.new = `https://carespot.gr/${this.model.brand.slug}/${newSlug}`;

        this.photos = this.model.photos.map(photo => ({
          ...photo,
          public_id: photo.public_id.replace(this.model.slug, newSlug),
        }));
      }
    },

    // TODO: Move this separately
    'general.is_new': function(newVal, oldVal) {
      if (newVal !== oldVal && oldVal !== undefined) {
        this.general = {
          ...this.general,
          new_expires_at:
            newVal === true
              ? moment()
                  .add(3, 'month')
                  .format('YYYY-MM-DD')
              : null,
        };
      }
    },

    // TODO: Move this separately
    'general.subsets': {
      /* eslint-disable camelcase */
      handler(newVal, oldVal) {
        if (this.isSet && !isEqual(newVal, oldVal)) {
          const totalWeight = round(
            newVal.reduce(
              (acc, { weight, pivot }) => acc + weight * pivot.count,
              0,
            ),
            2,
          );
          const totalBuyPrice = round(
            newVal.reduce(
              (acc, { buy_price, pivot }) => acc + buy_price * pivot.count,
              0,
            ),
            2,
          );
          const totalSellPrice = round(
            newVal.reduce(
              (acc, { sell_price, pivot }) => acc + sell_price * pivot.count,
              0,
            ),
            2,
          );
          const totalFinalPrice = round(
            totalSellPrice - (this.general.discount * totalSellPrice) / 100,
            2,
          );

          this.general = {
            ...this.general,
            weight: totalWeight,
            buy_price: totalBuyPrice,
            sell_price: totalSellPrice,
            final_price: totalFinalPrice,
          };
        }
      },
      deep: true,
    },
  },

  created() {
    this.setProduct(this.model);
  },

  methods: {
    ...mapActions({
      updateProduct: 'products/edit/updateProduct',
      checkForProductUpdates: 'products/edit/checkForProductUpdates',
      getProductPhotos: 'products/edit/getProductPhotos',
      updateSet: 'sets/edit/updateSet',
      updateGroup: 'groups/edit/updateGroup',
      getCollections: 'collections/all/getCollections',
    }),

    async submit() {
      try {
        this.errorMessages = [];
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        // Create an array with categories ids
        const categories = this.categories.map(category => {
          if (!category.id) {
            const pairs = Object.assign(
              {},
              this.categoriesPerType.low,
              this.categoriesPerType.mid,
            );

            return pairs[category.uuid].id;
          }

          return category.id;
        });

        this.isSaving = true;
        const data = {
          ...this.general,
          redirection: this.redirection,
          meta: this.meta,
          socialMeta: this.socialMeta,
          tags: this.general.tags.map(({ id }) => id),
          promos: this.general.promos.map(({ id }) => id),
          symptoms: this.general.symptoms.map(({ id }) => id),
          categories,
          collection_id: this.general.collection
            ? this.general.collection.id
            : null,
          filters: this.general.filterGroups.reduce((acc, filterGroup) => {
            const ids = get(filterGroup, 'selections', []).map(({ id }) => id);

            return flow(uniq, sortBy)([...acc, ...ids]);
          }, []),
          barcodes: this.barcodes.map(barcode => ({
            id: barcode.id,
            code: barcode.code,
            color_id: get(barcode, 'color.id', null),
            size_id: get(barcode, 'size.id', null),
            stock: barcode.stock,
            active: barcode.active,
          })),
          new_expires_at: this.general.new_expires_at
            ? moment(this.general.new_expires_at).format('DD-MM-YYYY')
            : null,
        };

        if (this.isProduct || this.isGift) {
          await this.updateProduct({
            uuid: this.model.uuid,
            product: data,
          });
        }

        if (this.isSet) {
          await this.updateSet({
            uuid: this.model.uuid,
            set: {
              ...data,
              subsets: this.general.subsets,
            },
          });
        }

        if (this.isGroup) {
          await this.updateGroup({
            uuid: this.model.uuid,
            group: {
              ...data,
              subgroups: this.general.subgroups.map(({ id, pivot }) => ({
                id,
                ...pivot,
              })),
            },
          });
        }
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το προιόν αποθηκεύτηκε',
        });
      } catch (err) {
        // TODO: Use a util method
        this.errorMessages = Object.entries(get(err, 'messages', [])).reduce(
          (acc, [key, value]) => [
            ...acc,
            {
              field: key,
              message: value,
            },
          ],
          [],
        );
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    askToDeleteProduct() {
      this.isOpen = !this.isOpen;
    },

    async closeModal(response) {
      try {
        this.isOpen = !this.isOpen;
        if (response) {
          await request.delete(`/products/${this.product.uuid}`);
          this.$router.push('/products');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.setProduct(this.model);

      this.$validator.reset();
    },

    setProduct(product) {
      this.general = pick(product, [
        'common_id',
        'erp_id',
        'title',
        'seo_title',
        'slug',
        'description',
        'content',
        'ingredients',
        'active',
        'buy_price',
        'sell_price',
        'discount',
        'final_price',
        'weight',
        'tax_id',
        'availability_id',
        'available_forever',
        'skroutz',
        'best_price',
        'contact_pigeon',
        'facebook',
        'google',
        'tags',
        'promos',
        'brand_id',
        'brand',
        'collection_id',
        'collection',
        'symptoms',
        'filters',
        'stock',
        'is_new',
        'bundles',
        'sets',
        'subsets',
        'groups',
        'subgroups',
        'new_expires_at',
        'type',
        'marketing_description',
      ]);

      this.categories = clone(product.categories);
      this.redirection = clone(
        product.redirection || {
          new: null,
          old: null,
        },
      );
      this.meta = clone(product.meta);

      const generalMeta = pick(this.generalModel.social_meta, [
        'og_description',
        'og_title',
        'og_type',
        'og_image',
        'twitter_description',
        'twitter_title',
        'twitter_image',
        'twitter_card',
      ]);

      this.socialMeta = Object.keys(generalMeta).reduce((acc, key) => {
        if (!acc[key]) {
          acc[key] = generalMeta[key];
        }

        return acc;
      }, product.social_meta);

      this.photos = clone(product.photos);
      this.barcodes = cloneWith(product.barcodes, barcodes =>
        barcodes.map(({ id, code, color, size, stock, photos, active }) => ({
          id,
          code,
          color,
          size,
          stock,
          photos,
          active,
          slug: this.general.slug,
        })),
      );
      const filterGroupsIds = product.filters.map(
        filter => filter.filter_group_id,
      );
      const filtersIds = product.filters.map(filter => filter.id);

      this.general.filterGroups = this.filterGroups.reduce(
        (acc, filterGroup) => {
          if (filterGroupsIds.includes(filterGroup.id)) {
            return [
              ...acc,
              {
                ...filterGroup,
                selections: filterGroup.filters.filter(filter =>
                  filtersIds.includes(filter.id),
                ),
              },
            ];
          }

          return acc;
        },
        [],
      );
    },

    updateRedirection(redirection) {
      this.redirection = redirection;
    },

    updateMeta(meta) {
      this.meta = meta;
    },

    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },

    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getProductPhotos({ uuid: this.$route.params.uuid });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },

    async handleUpdateBarcodePhoto() {
      try {
        this.isLoadingPhoto = true;
        try {
          const { data } = await request.get(
            `/products/${this.$route.params.uuid}/barcodes`,
          );

          if (data.error) {
            throw Error('Server-side validation failed');
          }

          this.barcodes = cloneWith(data.barcodes, barcodes =>
            barcodes.map(
              ({ id, code, color, size, stock, photos, active }) => ({
                id,
                code,
                color,
                size,
                stock,
                photos,
                active,
                slug: this.general.slug,
              }),
            ),
          );
        } catch (err) {
          throw err;
        }
        this.isLoadingPhoto = false;
      } catch (err) {
        this.isLoadingPhoto = false;
      }
    },

    changeContent(el, content) {
      this.general[el] = content === '<p><br></p>' ? '' : content;
    },

    selectBrand(brand) {
      this.general = {
        ...this.general,
        brand_id: brand.id,
        brand,
        collection_id: null,
        collection: null,
      };
    },

    removeBrand() {
      this.general = {
        ...this.general,
        brand_id: null,
        brand: null,
        collection_id: null,
        collection: null,
      };
    },

    updateCategories({ categories }) {
      this.categories = categories;
    },

    updatePrice(price) {
      this.general = {
        ...this.general,
        ...price,
      };
    },

    handleUpdateBarcodes(barcodes) {
      this.barcodes = barcodes;
    },

    updateAvailableForever({ value }) {
      this.general = {
        ...this.general,
        available_forever: value ? true : false,
      };
    },

    updateFeed({ value }, type) {
      this.general = {
        ...this.general,
        [type]: value ? true : false,
      };
    },

    updateActive({ value }) {
      this.general = {
        ...this.general,
        active: value ? true : false,
      };
    },

    updateIsNew({ value }) {
      this.general = {
        ...this.general,
        is_new: value ? true : false,
      };
    },

    onDeleteSubset(subset) {
      this.general = {
        ...this.general,
        subsets: this.general.subsets.filter(({ id }) => id !== subset.id),
      };
    },

    onSelectSubset(subset) {
      this.general = {
        ...this.general,
        subsets: [
          ...this.general.subsets,
          {
            ...subset,
            pivot: {
              count: 1,
            },
          },
        ],
      };
    },

    onChangeSubsetCount(id, count) {
      this.general = {
        ...this.general,
        subsets: this.general.subsets.map(subset => {
          if (subset.id === id) {
            return {
              ...subset,
              pivot: {
                count,
              },
            };
          }

          return subset;
        }),
      };
    },

    onDeleteSubgroup(subgroup) {
      this.general = {
        ...this.general,
        subgroups: this.general.subgroups.filter(
          ({ id }) => id !== subgroup.id,
        ),
      };
    },

    onSelectSubgroup(subgroup) {
      this.general = {
        ...this.general,
        subgroups: [
          ...this.general.subgroups,
          {
            pivot: {
              product_id: subgroup.id,
              group_id: this.model.id,
              color_id: null,
              size_id: null,
            },
            ...subgroup,
          },
        ],
      };
    },

    selectFilterGroup(filterGroup) {
      this.general = {
        ...this.general,
        filterGroups: [
          ...this.general.filterGroups,
          {
            ...filterGroup,
            selections: [],
          },
        ],
      };
    },

    removeFilterGroup(filterGroup) {
      this.general = {
        ...this.general,
        filterGroups: this.general.filterGroups.filter(
          ({ id }) => id !== filterGroup.id,
        ),
      };
    },

    selectFilter(filter) {
      this.general = {
        ...this.general,
        filterGroups: this.general.filterGroups.map(filterGroup =>
          filter.filter_group_id === filterGroup.id
            ? {
                ...filterGroup,
                selections: [...filterGroup.selections, filter],
              }
            : filterGroup,
        ),
      };
    },

    removeFilter(filter) {
      this.general = {
        ...this.general,
        filterGroups: this.general.filterGroups.map(filterGroup =>
          filter.filter_group_id === filterGroup.id
            ? {
                ...filterGroup,
                selections: filterGroup.selections.filter(
                  ({ id }) => id !== filter.id,
                ),
              }
            : filterGroup,
        ),
      };
    },

    async handleERPSync() {
      try {
        this.isSyncing = true;
        await this.checkForProductUpdates({
          uuid: this.model.uuid,
        });

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η ενημέρωση εκτελέστηκε επιτυχώς!!',
        });
        this.isSyncing = false;
      } catch (err) {
        this.isSyncing = false;

        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    onChangeColor({ id, color_id }) {
      const subgroups = this.general.subgroups.map(item =>
        item.id === id
          ? {
              ...item,
              color_id,
              pivot: {
                ...item.pivot,
                color_id,
              },
            }
          : item,
      );

      this.general = {
        ...this.general,
        subgroups,
      };
    },

    onChangeSize({ id, size_id }) {
      const subgroups = this.general.subgroups.map(item =>
        item.id === id
          ? {
              ...item,
              size_id,
              pivot: {
                ...item.pivot,
                size_id,
              },
            }
          : item,
      );

      this.general = {
        ...this.general,
        subgroups,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 0 auto !important;
}
.table-wrapper {
  overflow-x: auto;
}
</style>
