<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Πληροφορίες
        </div>
      </div>
      <div class="card-header-icon">
        <button
          v-tooltip="{ content: 'Προβολή' }"
          v-if="isEditing"
          class="button is-small"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-eye"/></span>
        </button>
        <button
          v-tooltip="{ content: 'Επεξεργασία' }"
          v-else
          :disabled="isDeleted"
          class="button is-small"
          @click="isEditing = !isEditing"
        >
          <span class="icon is-small"><i class="fa fa-pencil"/></span>
        </button>
      </div>
    </div>
    <div v-if="isEditing">
      <form @submit.prevent="submit">
        <div class="card-content">
          <div class="columns is-multiline is-desktop">
            <div class="column">
              <div class="field">
                <label class="label">Μέθοδος Πληρωμής *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model.trim.number="newOrder.checkout_method_id"
                      name="checkout_method_id"
                    >
                      <option :value="null">Επέλεξε μέθοδο πληρωμής</option>
                      <option
                        v-for="item in checkoutMethods"
                        :key="item.id"
                        :value="item.id"
                        :disabled="item.active === false"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Μέθοδος Αποστολής *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model.trim.number="newOrder.send_method_id"
                      name="send_method_id"
                    >
                      <option :value="null">Επέλεξε μέθοδο αποστολής</option>
                      <option
                        v-for="item in sendMethods"
                        :key="item.id"
                        :value="item.id"
                        :disabled="item.active === false"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="field">
                <label class="label">Μέθοδος Παραγγελίας</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model.trim.number="newOrder.order_method_id"
                      name="order_method_id"
                    >
                      <option :value="null">
                        Επέλεξε μέθοδο παραγγελίας
                      </option>
                      <option
                        v-for="item in orderMethods"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Σχολια για Courier</label>
                <div class="control">
                  <textarea
                    v-validate="'max:99'"
                    v-model="newOrder.customer_notes_courier"
                    name="customer_notes_courier"
                    class="textarea"
                    cols="30"
                    rows="2"
                  />
                </div>
                <div
                  v-show="errors.has('customer_notes_courier')"
                  class="help is-danger"
                >
                  Εισάγετε έως 99 χαρακτήρες
                </div>
              </div>
            </div>
            <div class="column is-full">
              <div class="field">
                <label class="label">Σχολια για παραγγελία</label>
                <div class="control">
                  <textarea
                    v-model="newOrder.customer_notes"
                    name="customer_notes"
                    class="textarea"
                    cols="30"
                    rows="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </form>
    </div>
    <div v-else class="card-content">
      <div class="columns is-multiline is-desktop">
        <div class="column">
          <div class="field">
            <label class="label">Μέθοδος Πληρωμής *</label>
            <div class="control">
              {{ newOrder.checkout_method.title }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Μέθοδος Αποστολής *</label>
            <div class="control">
              {{ newOrder.send_method.title }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Μέθοδος Παραγγελίας</label>
            <div class="control">
              {{ newOrder.order_method.title }}
            </div>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label">Σχολια για Courier</label>
            <div class="control">
              <p>{{ customerNotesCourier }}</p>
            </div>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <label class="label">Σχολια για παραγγελία</label>
            <div class="control">
              <p>{{ customerNotes }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pick, isEmpty } from 'lodash';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import EditItem from '@/views/components/EditItem';
import {
  calculateProductsSum,
  calculateSendCharges,
} from '@/utils/calculations';
import CHECKOUT_CHARGES from '@/constants/checkoutCharges';

export default {
  extends: EditItem,

  props: {
    isDeleted: Boolean,
    order: Object,
  },

  data() {
    return {
      newOrder: {},
      isEditing: false,
    };
  },

  computed: {
    ...mapGetters({
      checkoutMethods: 'checkoutMethods/list/getCheckoutMethods',
      sendMethods: 'getSendMethods',
      orderMethods: 'getOrderMethods',
      sendCharges: 'getSendCharges',
    }),

    customerNotes() {
      return this.newOrder.customer_notes || '------';
    },

    customerNotesCourier() {
      return this.newOrder.customer_notes_courier || '------';
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'newOrder.send_method_id': function(newValue) {
      this.newOrder.send_method = this.sendMethods.find(
        ({ id }) => id === newValue,
      ) || {
        title: '------',
      };

      this.updateSendCharges();
    },

    // eslint-disable-next-line func-names
    'newOrder.checkout_method_id': function(newValue, oldValue) {
      this.newOrder.checkout_method = this.checkoutMethods.find(
        ({ id }) => id === newValue,
      ) || {
        title: '------',
      };

      if (newValue !== oldValue && oldValue !== undefined) {
        let checkoutCharge = 0;
        if (this.newOrder.checkout_method.slug === 'pay_on_delivery') {
          let country;

          if (isEmpty(this.newOrder.send_address)) {
            ({ country } = this.newOrder.charge_address);
          } else {
            ({ country } = this.newOrder.send_address);
          }

          if (country) {
            country = this.$options.filters.capitalizeGreek(country);

            const isCyprus = country === 'ΚΥΠΡΟΣ';

            checkoutCharge = isCyprus
              ? CHECKOUT_CHARGES.cyprus
              : CHECKOUT_CHARGES.greece;
          }
        }

        this.newOrder.checkout_charge = checkoutCharge;

        this.updateOrder({
          order: {
            checkout_charge: checkoutCharge,
          },
        });
      }
    },

    // eslint-disable-next-line func-names
    'newOrder.order_method_id': function(newValue) {
      this.newOrder.order_method = this.orderMethods.find(
        ({ id }) => id === newValue,
      ) || {
        title: '------',
      };
    },

    'order.send_charge': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.newOrder.send_charge = newValue;
        }
      },
      immediate: true,
    },

    'order.charge_address': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.newOrder.charge_address = newValue;
        }
      },
      immediate: true,
    },

    'order.send_address': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.newOrder.send_address = newValue;
        }
      },
      immediate: true,
    },
  },

  /* eslint-disable camelcase */
  created() {
    const {
      checkout_method_id,
      checkout_method,
      send_method_id,
      send_method,
      order_method_id,
      order_method,
    } = this.order;

    this.newOrder = {
      ...pick(this.order, [
        'uuid',
        'checkout_charge',
        'checkout_method',
        'checkout_method_id',
        'send_method',
        'send_method_id',
        'send_charge',
        'order_method',
        'order_method_id',
        'customer_notes_courier',
        'customer_notes',
        'products',
        'charge_address',
        'send_address',
      ]),
      checkout_method_id: checkout_method_id || null,
      checkout_method: checkout_method || {
        title: '------',
      },
      send_method_id: send_method_id || null,
      send_method: send_method || {
        title: '------',
      },
      order_method_id: order_method_id || null,
      order_method: order_method || {
        title: '------',
      },
    };
  },

  methods: {
    ...mapMutations(['updateOrder']),
    ...mapActions(['updateOrderMethods']),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updateOrderMethods({
          order: {
            ...this.newOrder,
            checkout_charge: this.newOrder.checkout_charge || 0,
            send_charge: this.newOrder.send_charge || 0,
          },
        });
        this.isSaving = false;
        this.isEditing = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία ενημερώθηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      const {
        checkout_method_id,
        checkout_method,
        send_method_id,
        send_method,
        order_method_id,
        order_method,
      } = this.order;

      this.newOrder = {
        ...pick(this.order, [
          'uuid',
          'checkout_method',
          'checkout_method_id',
          'send_method',
          'send_method_id',
          'send_charge',
          'order_method',
          'order_method_id',
          'customer_notes_courier',
          'customer_notes',
          'products',
          'charge_address',
          'send_address',
        ]),
        checkout_method_id: checkout_method_id || null,
        checkout_method: checkout_method || {
          title: '------',
        },
        send_method_id: send_method_id || null,
        send_method: send_method || {
          title: '------',
        },
        order_method_id: order_method_id || null,
        order_method: order_method || {
          title: '------',
        },
      };
    },

    // The Order is already existing (EDIT) so we must use its own send_charge
    getAppropriateSendCharges(isCyprus) {
      const sendCharges = isCyprus ? this.sendCharges.cy : this.sendCharges.gr;
      sendCharges.sum[0].charge = this.newOrder.send_charge;
      return sendCharges;
    },

    updateSendCharges() {
      if (!isEmpty(this.newOrder.charge_address)) {
        let country;
        if (isEmpty(this.newOrder.send_address)) {
          ({ country } = this.newOrder.charge_address);
        } else {
          ({ country } = this.newOrder.send_address);
        }

        if (country) {
          country = this.$options.filters.capitalizeGreek(country);

          const isCyprus = country === 'ΚΥΠΡΟΣ';

          let send_charge;

          if (this.newOrder.send_method?.slug === 'pharmacy') {
            send_charge = 0;
          } else if (this.newOrder.send_method?.slug === 'box_now') {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= 39 ? 0 : 1.5;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              this.getAppropriateSendCharges(isCyprus),
            );
          }

          this.updateOrder({
            order: {
              send_charge,
            },
          });
        }
      }
    },
  },
};
</script>
