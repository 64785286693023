<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" @click="handleClose" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Επεξεργασία Φωτογραφίας</p>
        <button type="button" class="delete" @click="handleClose" />
      </header>
      <form @submit.prevent.stop="submit">
        <section class="modal-card-body">
          <label class="label">Slug *</label>
          <div class="field has-addons">
            <div class="control">
              <a class="button">
                {{ prefix }}
              </a>
            </div>
            <div class="control is-expanded">
              <input
                v-validate="'required|regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                v-model.trim="newPhoto.slug"
                type="text"
                class="input"
                name="slug"
              />
            </div>
            <div class="control">
              <a class="button">
                {{ suffix }}
              </a>
            </div>
          </div>
          <div v-show="errors.has('slug')" class="help is-danger">
            Εισάγετε έγκυρο slug
          </div>
        </section>
        <footer class="modal-card-foot">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import { last, split } from 'lodash';
import FormSubmit from '@/views/components/FormSubmit';

export default {
  components: {
    FormSubmit,
  },

  props: {
    isOpen: Boolean,
    isSaving: Boolean,
    photo: Object,
  },

  data() {
    return {
      newPhoto: {},
      prefix: '',
      suffix: '',
    };
  },

  watch: {
    photo: {
      handler(newVal) {
        const slug = last(split(newVal.public_id, '/'));
        [this.prefix] = split(newVal.public_id, slug);
        this.suffix = last(split(slug, '-'));
        this.newPhoto = {
          slug: split(slug, `-${this.suffix}`)[0],
          is_avatar: newVal.is_avatar,
          is_banner: newVal.is_banner,
        };
      },
      immediate: true,
    },
  },

  methods: {
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.$emit('on-edit', {
          ...this.newPhoto,
          slug: `${this.prefix}${this.newPhoto.slug}-${this.suffix}`,
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleClose() {
      this.$emit('on-close');

      this.reset();
    },

    reset() {
      const slug = last(split(this.photo.public_id, '/'));
      this.newPhoto = {
        slug: split(slug, `-${this.suffix}`)[0],
        is_avatar: this.photo.is_avatar,
        is_banner: this.photo.is_banner,
      };
      this.errors.clear();
    },
  },
};
</script>
