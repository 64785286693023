<template>
  <tr :class="{ 'is-selected': selected, 'hidden-print': !selected }">
    <td class="hidden-print has-text-centered">
      <checkbox v-model="selected" name="selected" />
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      {{ item.title }}
    </td>
    <td class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="true"
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>
    <td class="has-text-centered hidden-print">
      <div class="buttons is-centered">
        <router-link
          :to="{
            name: 'marketing.periodicDiscounts.edit',
            params: { uuid: item.id },
          }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';

export default {
  extends: PaginatedListItem,
};
</script>
