<template>
  <nav class="navbar has-shadow">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <strong class="title">
          Ψηφιακό Ράφι
        </strong>
      </router-link>
      <a class="navbar-item" @click="toggleSidebar(!showSidebar)">
        <span class="icon is-small">
          <i v-if="showSidebar" class="fa fa-navicon" />
          <i v-else class="fa fa-angle-right" />
        </span>
      </a>

      <button
        :class="['button navbar-burger', { 'is-active': showMobileMenu }]"
        @click="toggleMobileMenu"
      >
        <span />
        <span />
        <span />
      </button>
    </div>

    <div :class="['navbar-menu', { 'is-active': showMobileMenu }]">
      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is-hidden-touch">
            <avatar :email="user.email" :alt="user.name" class="avatar" />
            {{ user.name }}
          </a>

          <div class="navbar-dropdown is-right">
            <router-link
              v-if="isSuperAdmin"
              :to="{ name: 'users.edit', params: { uuid: user.uuid } }"
              class="navbar-item"
            >
              <span class="icon"><i class="fa fa-user"/></span
              ><span class="dropdown-buttons">Προφίλ</span>
            </router-link>
            <span v-if="isSuperAdmin" class="navbar-divider" />
            <a class="navbar-item dropdown-buttons" @click="logoutUser">
              <span class="icon"><i class="fa fa-power-off"/></span
              ><span class="dropdown-buttons">Αποσύνδεση</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import Avatar from '@/views/components/Avatar';

export default {
  components: {
    Avatar,
  },

  data() {
    return {
      showMobileMenu: false,
      innerWidth: 0,
    };
  },

  computed: {
    ...mapGetters({
      user: 'getUser',
      showSidebar: 'getIsOpen',
    }),
    isSuperAdmin() {
      return this.user.authority === 1;
    },
  },

  watch: {
    innerWidth: {
      handler(newVal, oldVal) {
        if (newVal < 768 && (oldVal === 0 || oldVal >= 768)) {
          this.toggleSidebar(false);
        }
      },
    },
  },

  async created() {
    window.addEventListener('resize', e =>
      this.handleResize(e.target.innerWidth),
    );

    this.handleResize(window.innerWidth);
  },

  destroyed() {
    window.removeEventListener('resize', e =>
      this.handleResize(e.target.innerWidth),
    );
  },

  methods: {
    ...mapMutations(['toggleSidebar']),
    ...mapActions(['logoutUser']),
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },

    handleResize(innerWidth) {
      this.innerWidth = innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  color: #ffffff;
}
.navbar {
  background-color: #242424;
  border-top: 3px solid black;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 22;
}

a.navbar-item,
a.navbar-link {
  background-color: #242424;
  color: #ffffff;

  &:hover {
    color: #22a684;
  }
}

.navbar-dropdown {
  a.navbar-item {
    background-color: #ffffff;
    color: #dddddd;

    &:hover {
      color: #22a684;
    }
  }
}

.navbar-burger {
  background-color: #242424;
  border-color: #242424;
  box-shadow: none;
  color: #ffffff;

  &.is-active {
    color: #22a684;
  }

  &:focus {
    box-shadow: none;
  }
}

.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #242424;
}

.avatar {
  border: 1px solid #22a684;
  margin-right: 10px;
}

.dropdown-buttons {
  color: #242424;

  &:hover {
    color: #22a684;
  }
}
</style>
