<template>
  <div v-if="option.type === 'boolean'" class="field">
    <label class="label">Επιλογές</label>

    <div class="control">
      <div class="select is-fullwidth">
        <select
          :name="option.title"
          :value="selectedOption.value"
          @change="changeValue"
        >
          <option :value="null">Επέλεξε</option>
          <option :value="false">Όχι</option>
          <option :value="true">Ναί</option>
        </select>
      </div>
    </div>
  </div>

  <div v-else-if="option.type === 'options'" class="field">
    <label class="label">Επιλογές</label>

    <div class="control">
      <div class="select is-fullwidth">
        <select
          :name="option.title"
          :value="selectedOption.value"
          @change="changeValue"
        >
          <option :value="null">Επέλεξε</option>
          <option
            v-for="item in getOptionsFromString(option.options)"
            :key="item[option.optionsValue]"
            :value="item[option.optionsValue]"
          >
            {{ item.title }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-else-if="option.type === 'search'" class="field">
    <label class="label">Αναζήτηση</label>

    <div class="control">
      <BrandsSearch
        v-if="option.title === 'brand_id'"
        :brand="selectedOption.value"
        @select-brand="selectItem"
      />

      <CategoriesSearch
        v-if="option.title === 'category_id'"
        :category="selectedOption.value"
        @select-category="selectItem"
      />

      <FilterGroupsSearch
        v-if="option.title === 'filter_group_id'"
        :filter-group="selectedOption.value"
        @select-filter-group="selectItem"
      />

      <FiltersSearch
        v-if="option.title === 'filter_id'"
        :filter="selectedOption.value"
        @select-filter="selectItem"
      />
    </div>
  </div>

  <div v-else-if="option.type === 'range'" class="option">
    <label class="label">Επιλογές</label>

    <div class="field has-addons is-range">
      <div class="control">
        <input
          :value="selectedOption.value.start"
          name="start"
          class="input"
          type="number"
          step="0.01"
          min="0"
          placeholder="Από"
          @change="changeValue"
        />
      </div>

      <div class="control">
        <input
          :value="selectedOption.value.end"
          name="end"
          class="input"
          type="number"
          step="0.01"
          min="0"
          placeholder="Έως"
          @change="changeValue"
        />
      </div>
    </div>
  </div>

  <div v-else-if="option.type === 'date_range'" class="option">
    <label class="label">Επιλογές</label>

    <div class="field has-addons is-range">
      <div class="control">
        <Datepicker
          :value="selectedOption.value.start"
          name="start"
          format="yyyy-MM-dd"
          language="el"
          wrapper-class="field"
          input-class="input"
          placeholder="πχ: 02-12-2017"
          @selected="date => changeDate(date, 'start')"
        />
      </div>

      <div class="control">
        <Datepicker
          :value="selectedOption.value.end"
          name="end"
          format="yyyy-MM-dd"
          language="el"
          wrapper-class="field"
          input-class="input"
          placeholder="πχ: 02-12-2017"
          @selected="date => changeDate(date, 'end')"
        />
      </div>
    </div>
  </div>

  <div v-else-if="option.type === 'string'" class="field">
    <label class="label">Τιμή</label>

    <div class="control">
      <input
        :name="option.title"
        :value="selectedOption.value"
        type="text"
        class="input"
        @change="changeValue"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import _clone from 'lodash/clone';
import { mapGetters } from 'vuex';
import BrandsSearch from './components/BrandsSearch';
import CategoriesSearch from './components/CategoriesSearch';
import FilterGroupsSearch from './components/FilterGroupsSearch';
import FiltersSearch from './components/FiltersSearch';

export default {
  components: {
    Datepicker,
    BrandsSearch,
    CategoriesSearch,
    FilterGroupsSearch,
    FiltersSearch,
  },
  props: {
    option: Object,
  },
  data: () => ({
    selectedOption: {},
    types: [
      {
        title: 'Product',
        value: 'product',
      },
      {
        title: 'Group',
        value: 'group',
      },
      {
        title: 'Set',
        value: 'set',
      },
      {
        title: 'Gift',
        value: 'gift',
      },
    ],
    erp_abc_colors: [
      {
        title: 'Green',
        value: 'green',
      },
      {
        title: 'New Green',
        value: 'newgreen',
      },
      {
        title: 'Red',
        value: 'red',
      },
      {
        title: 'Black',
        value: 'black',
      },
      {
        title: 'Dead',
        value: 'dead',
      },
    ],
    genders: [
      {
        title: 'Άνδρας',
        value: 'male',
      },
      {
        title: 'Γυναίκα',
        value: 'female',
      },
    ],
    invoice_types: [
      {
        title: 'Απόδειξη',
        value: 'receipt',
      },
      {
        title: 'Τιμολόγιο',
        value: 'invoice',
      },
    ],
    skroutzOrdersStates: [
      { value: 'open', title: 'Νέες Παραγγελίες' },
      {
        value: 'rejected',
        title: 'Rejected Παραγγελίες',
      },
      {
        value: 'accepted',
        title: 'Accepted Παραγγελίες',
      },
      {
        value: 'dispatched',
        title: 'Dispatched Παραγγελίες',
      },
      {
        value: 'cancelled',
        title: 'Cancelled Παραγγελίες',
      },
      {
        value: 'expired',
        title: 'Expired Παραγγελίες',
      },
      {
        value: 'delivered',
        title: 'Delivered Παραγγελίες',
      },
      {
        value: 'partially_returned',
        title: 'Partially Returned Παραγγελίες',
      },
      {
        value: 'returned',
        title: 'Returned Παραγγελίες',
      },
      {
        value: 'for_return',
        title: 'For Return Παραγγελίες',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      availabilities: 'getAvailabilities',
      checkoutMethods: 'checkoutMethods/list/getCheckoutMethods',
      orderMethods: 'getOrderMethods',
      sendMethods: 'getSendMethods',
      taxes: 'taxes/list/getTaxes',
    }),
  },
  watch: {
    option: {
      handler(newVal) {
        this.selectedOption = _clone(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.selectedOption = _clone(this.option);
  },
  methods: {
    getOptionsFromString(options) {
      return this[options];
    },
    changeValue({ target }) {
      const { name, type } = target;
      let { value } = target;

      if (this.selectedOption.type === 'range') {
        if (type === 'number') {
          value = parseFloat(value);
        }

        this.selectedOption.value[name] = value;
      } else {
        this.selectedOption.value = value;
      }
    },
    changeDate(date, name) {
      this.selectedOption.value[name] = moment(date).format('YYYY-MM-DD');
    },
    selectItem(item) {
      this.selectedOption.value = item;
    },
  },
};
</script>

<style scoped>
.option {
  margin-bottom: 0.75rem;
}

.is-range .control {
  flex: 1;
}
</style>
