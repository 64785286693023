<template>
  <tr>
    <td class="hidden-print has-text-centered">
      <ProductPhoto :type="item.type" :photos="photos" />
    </td>
    <td class="has-text-centered">
      <template v-if="hasCode">
        <p>{{ item.pivot.code }}</p>
      </template>
      <template v-else>
        <p
          v-for="(barcode, index) in item.barcodes"
          :key="index"
          class="hidden-print"
        >
          {{ barcode.code }}
        </p>
      </template>
    </td>
    <td class="has-text-centered">
      {{ item.common_id }}
    </td>
    <td class="has-text-centered" style="maxWidth: 250px">
      <router-link :to="{ name: 'products.edit', params: { uuid: item.uuid } }">
        {{ item.title }}
      </router-link>
      <template v-if="hasCode">
        <p v-if="hasColor">Χρώμα: {{ item.pivot.color_title }}</p>
        <p v-else-if="hasSize">Μέγεθος: {{ item.pivot.size_title }}</p>
      </template>
    </td>
    <td class="has-text-centered">
      {{ item.sell_price }}
    </td>
    <td class="has-text-centered">
      {{ item.discount }}
    </td>
    <td class="has-text-centered">
      {{ item.final_price }}
    </td>
    <td class="has-text-centered hidden-print">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        disabled
      />
    </td>
    <td class="has-text-centered visible-print">
      {{ item.active === true ? 'Ναί' : 'Όχι' }}
    </td>
    <td class="has-text-centered hidden-print">
      <router-link
        :to="{ name: 'products.edit', params: { uuid: item.uuid } }"
        class="button is-small"
      >
        <span class="icon is-small">
          <span class="fa fa-fw fa-pencil" />
        </span>
      </router-link>
    </td>
  </tr>
</template>

<script>
import ProductPhoto from '@/views/components/ProductPhoto';

export default {
  components: {
    ProductPhoto,
  },

  props: {
    index: Number,
    item: Object,
  },

  computed: {
    photos() {
      if (this.item.pivot.photo_public_id) {
        return [
          { public_id: this.item.pivot.photo_public_id, is_avatar: true },
        ];
      }

      return [];
    },

    hasCode() {
      return !!this.item.pivot.code;
    },

    hasSize() {
      return !!this.item.pivot.size_title;
    },

    hasColor() {
      return !!this.item.pivot.color_title;
    },
  },
};
</script>
