<template>
  <div class="filters hidden-print">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="field has-addons">
          <div v-if="hasOptions" class="control">
            <a
              ref="trigger"
              :class="['button', { 'is-open': isOpen }]"
              @click="toggleFilters"
            >
              <span>Φίλτρα</span>
              <span class="icon is-small"><i class="fa fa-caret-down"/></span>
            </a>
            <div v-click-outside="onClickOutside" v-if="isOpen" class="options">
              <div class="field">
                <label class="label">Φίλτρο</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="selectedOption">
                      <option :value="null">Επέλεξε Φίλτρο</option>
                      <option
                        v-for="option in availableOptions"
                        :key="option.title"
                        :value="option"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <FilterItem
                v-if="hasSelectedOption"
                ref="filter"
                :option="selectedOption"
              />

              <hr />

              <div class="control">
                <button
                  :disabled="!selectedOption"
                  class="button is-primary is-fullwidth"
                  @click="addFilter"
                >
                  Προσθήκη Φίλτρου
                </button>
              </div>
            </div>
          </div>
          <div v-else class="control">
            <a class="button is-static">
              <span class="icon is-small"><i class="fa fa-search"/></span>
            </a>
          </div>
          <div :class="['control is-expanded', { 'is-loading': isLoading }]">
            <input
              :placeholder="`${generalLabel}...`"
              v-model="general"
              type="text"
              class="input"
            />
          </div>
        </div>
      </div>

      <div v-if="hasSelections" class="column is-full filters-column">
        <FilterTag
          v-for="key in Object.keys(selections)"
          :key="key"
          :filter="selections[key]"
          @dropFilter="dropFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import Confirmation from '@/views/components/Confirmation';
import FilterItem from './components/Filter';
import FilterTag from './components/FilterTag';

export default {
  components: {
    Confirmation,
    FilterItem,
    FilterTag,
  },
  props: {
    generalLabel: String,
    isLoading: Boolean,
    options: Array,
    filters: Object,
  },
  data: () => ({
    isOpen: false,
    general: '',
    selectedOption: null,
    selections: {},
  }),
  computed: {
    hasSelectedOption() {
      return !_isEmpty(this.selectedOption);
    },
    hasSelections() {
      return !_isEmpty(this.selections);
    },
    hasOptions() {
      return this.options.some(({ title }) => title !== 'general');
    },
    availableOptions() {
      const selectedOptions = Object.keys(this.selections);

      return this.options.filter(
        ({ title }) => title !== 'general' && !selectedOptions.includes(title),
      );
    },
  },
  watch: {
    selections(newVal, oldVal) {
      if (!_isEqual(newVal, oldVal)) {
        this.navigate(newVal);
      }
    },
    filters(newVal, oldVal) {
      if (!_isEqual(newVal, oldVal)) {
        const keys = Object.keys(newVal);
        this.selections = this.options.reduce((acc, option) => {
          if (keys.includes(option.title)) {
            if (option.title === 'general') {
              this.general = newVal[option.title];
            }

            if (option.type === 'range') {
              return {
                ...acc,
                [option.title]: {
                  ...option,
                  value: {
                    start: newVal[option.title].start,
                    end: newVal[option.title].end,
                  },
                },
              };
            }

            if (option.type === 'search') {
              return {
                ...acc,
                [option.title]: {
                  ...option,
                  value: {
                    id: newVal[option.title],
                    title: this.selections[option.title].value.title,
                  },
                },
              };
            }

            return {
              ...acc,
              [option.title]: {
                ...option,
                value: newVal[option.title],
              },
            };
          }

          return acc;
        }, {});
      }
    },
    // eslint-disable-next-line func-names
    general: _debounce(function(newVal) {
      const filter = this.options.find(option => option.title === 'general');

      if (newVal) {
        // Add general selection when it gets a value
        this.selections = {
          ...this.selections,
          ...{ [filter.title]: { ...filter, value: newVal } },
        };
      } else {
        // Drop general selection when it becomes empty
        this.dropFilter('general');
      }
    }, 500),
  },
  methods: {
    toggleFilters() {
      this.isOpen = !this.isOpen;
    },
    addFilter() {
      const filter = this.$refs.filter.selectedOption;

      this.selections = {
        ...this.selections,
        ...(filter.value !== null ? { [filter.title]: filter } : {}),
      };

      this.isOpen = false;
      this.selectedOption = null;
    },
    dropFilter(title) {
      if (title === 'general') {
        this.general = '';
      }

      this.selections = Object.keys(this.selections).reduce((acc, key) => {
        if (this.selections[key].title !== title) {
          return {
            ...acc,
            [key]: this.selections[key],
          };
        }

        return acc;
      }, {});
    },
    onClickOutside(e) {
      // We exclude trigger button from this change
      if (!this.$refs.trigger.contains(e.target)) {
        this.isOpen = false;
        this.selectedOption = null;
      }
    },
    navigate(selection) {
      this.$emit('navigate', {
        page: 1,
        filters: this.prepareFilters(selection),
      });
    },
    prepareFilters(selections) {
      return Object.keys(selections).reduce((acc, key) => {
        const { value, type } = selections[key];

        let values = {};

        if (value) {
          if (_isObject(value)) {
            if (type === 'range' || type === 'date_range') {
              values = { [key]: { start: value.start, end: value.end } };
            }

            if (type === 'search') {
              values = { [key]: value.id };
            }
          } else {
            values = { [key]: value };
          }

          return {
            ...acc,
            ...values,
          };
        }

        return acc;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  position: relative;

  .button.is-open {
    border-bottom-left-radius: 0 !important;
  }

  .options {
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 0 0 5px 5px;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: absolute;
    width: 300px;
    z-index: 10;

    &:hover {
      border-color: #b5b5b5;
    }
  }
}

.filters-column {
  padding-top: 0;
}
</style>
