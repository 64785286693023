<template>
  <div class="card hidden-print">
    <div class="card-header">
      <div class="card-header-title">
        <div class="title is-6 is-marginless">
          Στοιχεία Χρέωσης
        </div>
      </div>

      <div v-if="order.orderable_id && !isGuest" class="card-header-icon">
        <button
          v-tooltip="{ content: 'Προσθήκη Δ/νσης' }"
          class="button is-small"
          @click="openModal"
        >
          <span class="icon is-small"><i class="fa fa-plus"/></span>
        </button>
      </div>
    </div>
    <div>
      <div class="card-content">
        <div class="columns">
          <div class="column is-half">
            <div
              v-for="type in invoiceTypes"
              :key="type.key"
              class="is-inline-block control radio-control"
            >
              <radio
                :value="type.key"
                v-model="invoiceType"
                name="invoiceType"
              />
              <label>
                {{ type.label }}
              </label>
            </div>
          </div>
        </div>
        <template v-if="canSelectAddress">
          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <label class="label">Επέλεξε Διεύθυνση *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-validate="'required'"
                      :value="newAddress.id"
                      name="userAddress"
                      @input="selectAddress"
                    >
                      <option
                        v-for="item in order.customer.profile.addresses"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.region }}, {{ item.city }}, ΤΚ:
                        {{ item.postcode }}, {{ item.street }}
                        {{ item.streetNumber }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-show="errors.has('userAddress')" class="help is-danger">
                  Επέλεξε Διεύθυνση
                </div>
              </div>
            </div>
          </div>
          <hr />
        </template>
        <div class="columns columns__address">
          <div class="column">
            <div class="field">
              <label class="label">Όνομα *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newAddress.firstName"
                  type="text"
                  class="input"
                  name="firstName"
                  @input="updateAddress"
                />
              </div>
              <div v-show="errors.has('firstName')" class="help is-danger">
                Εισάγετε όνομα
              </div>
            </div>

            <div class="field">
              <label class="label">Επώνυμο *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newAddress.lastName"
                  type="text"
                  class="input"
                  name="lastName"
                  @input="updateAddress"
                />
              </div>
              <div v-show="errors.has('lastName')" class="help is-danger">
                Εισάγετε επώνυμο
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Κινητό*</label>
              <div class="control">
                <vue-tel-input
                  v-validate="'required'"
                  :value="phoneNumbers.mobile"
                  enabled-country-code
                  placeholder="Εισάγετε Κινητό"
                  name="mobile"
                  input-id="add_order_mobile"
                  @input="onInputMobile"
                />
                <p
                  v-show="errors.has('mobile') || errors.has('mobile-invalid')"
                  class="help is-danger"
                >
                  Εισάγετε έγκυρο κινητό τηλέφωνο
                </p>
              </div>
            </div>

            <div class="field">
              <label class="label">Σταθερό</label>
              <div class="control">
                <vue-tel-input
                  :value="phoneNumbers.telephone"
                  enabled-country-code
                  input-id="add_order_telephone"
                  name="telephone"
                  placeholder="Εισάγετε Σταθερό"
                  @input="onInputTelephone"
                />
                <p v-show="errors.has('telephone')" class="help is-danger">
                  Εισάγετε έγκυρο σταθερό τηλέφωνο
                </p>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Οδός *</label>
              <div class="control">
                <GmapAutocomplete
                  v-validate="'required'"
                  :value="newAddress.street"
                  :options="{
                    types: ['geocode'],
                    componentRestrictions: {
                      country: ['gr', 'cy'],
                    },
                  }"
                  class="input"
                  type="text"
                  name="street"
                  placeholder="Εισάγετε Οδό, Αριθμό & Πόλη"
                  autocomplete="new-password"
                  @focus.native="resetAutocomplete"
                  @keydown.enter.prevent.native
                  @place_changed="updatePlace"
                  @input.native="handleStreetChange"
                />
              </div>
              <div v-show="errors.has('street')" class="help is-danger">
                Εισάγετε Οδό, Αριθμό & Πόλη
              </div>
            </div>

            <div class="field">
              <label class="label">Αριθμός *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newAddress.streetNumber"
                  class="input"
                  type="text"
                  name="streetNumber"
                  placeholder="Εισάγετε Aριθμό"
                  autocomplete="new-password"
                  @focus="resetAutocomplete"
                  @input="updateAddress"
                />
              </div>
              <div v-show="errors.has('streetNumber')" class="help is-danger">
                Εισάγετε αριθμό
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">ΤΚ *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newAddress.postcode"
                  class="input"
                  name="postcode"
                  placeholder="Εισάγετε ΤΚ"
                  autocomplete="new-password"
                  @focus="resetAutocomplete"
                  @input="updateAddress"
                />
              </div>
              <div v-show="errors.has('postcode')" class="help is-danger">
                Εισάγετε ΤΚ
              </div>
            </div>

            <div class="field">
              <label class="label">Πόλη *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newAddress.city"
                  class="input"
                  name="city"
                  placeholder="Εισάγετε Πόλη"
                  autocomplete="new-password"
                  @focus="resetAutocomplete"
                  @input="updateAddress"
                />
              </div>
              <div v-show="errors.has('city')" class="help is-danger">
                Εισάγετε πόλη
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Νομός *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newAddress.region"
                  class="input"
                  name="region"
                  placeholder="Εισάγετε Νομό"
                  autocomplete="new-password"
                  @focus="resetAutocomplete"
                  @input="updateAddress"
                />
              </div>
              <div v-show="errors.has('region')" class="help is-danger">
                Εισάγετε νομό
              </div>
            </div>

            <div class="field">
              <label class="label">Χώρα *</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select
                    v-validate="'required'"
                    ref="country"
                    :value="newAddress.country"
                    name="country"
                    @change="updateAddress"
                  >
                    <option
                      v-for="(country, index) in countryOptions"
                      :key="index"
                      :value="country"
                    >
                      {{ country }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="invoiceSelected" class="columns">
          <div class="column is-one-fifth">
            <div class="field">
              <label class="label">Εταιρεία *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newInvoice.company"
                  type="text"
                  class="input"
                  name="company"
                  @input="updateInvoice"
                />
              </div>
              <div v-show="errors.has('company')" class="help is-danger">
                Εισάγετε εταιρεία
              </div>
            </div>
          </div>

          <div class="column is-one-fifth">
            <div class="field">
              <label class="label">Ιδιότητα *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newInvoice.profession"
                  type="text"
                  class="input"
                  name="profession"
                  @input="updateInvoice"
                />
              </div>
              <div v-show="errors.has('profession')" class="help is-danger">
                Εισάγετε ιδιότητα
              </div>
            </div>
          </div>

          <div class="column is-one-fifth">
            <div class="field">
              <label class="label">ΑΦΜ *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  :value="newInvoice.vat_number"
                  type="text"
                  class="input"
                  name="vat_number"
                  @input="updateInvoice"
                />
              </div>
              <div v-show="errors.has('vat_number')" class="help is-danger">
                Εισάγετε ΑΦΜ
              </div>
            </div>
          </div>

          <div class="column is-two-fifths">
            <div class="field">
              <label class="label">{{ vatOfficeLabel }}</label>
              <div class="control">
                <multiselect
                  v-validate="'required_if:country,ΕΛΛΑΔΑ,Ελλάδα'"
                  :value="newInvoice.vat_office"
                  :options="vatOffices"
                  :searchable="true"
                  :show-labels="false"
                  name="vat_office"
                  track-by="id"
                  label="title"
                  placeholder="Επιλέξτε Δ.Ο.Υ."
                  selected-label="Επιλεγμένο"
                  select-label="Πατήστε enter για επιλογή"
                  deselect-label="Πατήστε enter για απο-επιλογή"
                  @select="handleSelectVatOffice"
                  @remove="handleRemoveVatOffice"
                />
              </div>
              <div v-show="errors.has('vat_office')" class="help is-danger">
                Εισάγετε ΔΟΥ
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { clone, cloneDeep, get, isEmpty, pick } from 'lodash';
import Multiselect from 'vue-multiselect';
import gMapAutocomplete from '@/utils/gMapAutocomplete';
import {
  calculateSendCharges,
  calculateProductsSum,
} from '@/utils/calculations';
import CHECKOUT_CHARGES from '@/constants/checkoutCharges';
import transformations from '@/utils/transformations';
import AddressMixin from '@/mixins/Address';
import EditItem from '@/views/components/EditItem';

export default {
  inject: ['$validator'],

  components: {
    Multiselect,
  },

  extends: EditItem,

  mixins: [AddressMixin],

  props: {
    order: Object,

    vatOffices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      invoiceTypes: [
        {
          key: 'no_invoice',
          label: 'Απόδειξη',
        },
        {
          key: 'invoice',
          label: 'Τιμολόγιο',
        },
      ],
      invoiceType: 'no_invoice',
      phoneNumbers: {
        mobile: '',
        telephone: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      newOrder: 'getNewOrder',
      customerType: 'getCustomerType',
      isGuest: 'getIsGuest',
      customer: 'getCustomer',
      sendCharges: 'getSendCharges',
      isPharmacy: 'getIsPharmacy',
      isBoxnow: 'getIsBoxnow',
      isPayOnDelivery: 'getIsPayOnDelivery',
    }),

    addressesNumber() {
      return get(this.customer, 'profile.addresses', []).length;
    },

    canSelectAddress() {
      return !this.isGuest && this.addressesNumber > 0;
    },

    invoiceSelected() {
      return this.invoiceType === 'invoice';
    },

    newAddress() {
      if (this.newOrder.charge_address) {
        return clone(this.newOrder.charge_address);
      }

      return {
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        telephone: '',
        mobile: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        region: '',
        country: 'ΕΛΛΑΔΑ',
      };
    },

    newInvoice() {
      if (this.invoiceSelected) {
        const address = {
          ...pick(this.newAddress, [
            'firstName',
            'lastName',
            'telephone',
            'mobile',
            'street',
            'streetNumber',
            'postcode',
            'city',
            'region',
            'country',
          ]),
        };

        if (!isEmpty(this.newOrder.invoice)) {
          return {
            ...cloneDeep(this.newOrder.invoice),
            ...address,
          };
        }

        return {
          ...address,
          company: '',
          profession: '',
          vat_number: '',
          vat_office: null,
          vat_office_id: null,
        };
      }

      return null;
    },

    country() {
      return this.$options.filters.capitalizeGreek(this.newAddress.country);
    },

    isVatOfficeRequired() {
      return this.country !== 'ΚΥΠΡΟΣ';
    },

    vatOfficeLabel() {
      let label = 'ΔΟΥ';
      if (this.isVatOfficeRequired) {
        label += ' *';
      }

      return label;
    },
  },

  watch: {
    newAddress: {
      handler(newVal) {
        if (newVal.mobile && !this.phoneNumbers.mobile) {
          this.phoneNumbers.mobile = newVal.mobile;
        }

        if (newVal.telephone && !this.phoneNumbers.telephone) {
          this.phoneNumbers.telephone = newVal.telephone;
        }
      },
      immediate: true,
    },

    canSelectAddress: {
      handler(newVal) {
        if (newVal) {
          // eslint-disable-next-line camelcase
          const charge_address = this.customer.profile.addresses[0];

          this.updateNewOrder({ charge_address });
        }
      },
    },

    country: {
      handler() {
        this.updateCharges();
      },
      immediate: true,
    },

    invoiceSelected: {
      handler() {
        this.updateNewOrder({ invoice: this.newInvoice });
        this.$validator.pause();
        this.$nextTick(() => {
          this.$validator.reset();
          this.$validator.resume();
        });
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      updateNewOrder: 'updateNewOrder',
      updateCountry: 'toggleCountry',
    }),

    openModal() {
      this.isOpen = true;
    },

    closeModal() {
      this.isOpen = false;
    },

    selectAddress(e) {
      const selectedAddress = this.customer.profile.addresses.find(
        item => item.id === parseInt(e.target.value, 10),
      );

      // eslint-disable-next-line camelcase
      const charge_address = transformations.capitalizeAddress(selectedAddress);

      this.updateNewOrder({ charge_address });
    },

    handleStreetChange($event) {
      const { name, value } = $event.target;

      this.updateNewOrder({
        charge_address: {
          ...this.newAddress,
          street: value,
        },
      });

      this.$nextTick(() => {
        this.$validator.validate(name);
      });
    },

    onInputTelephone(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'telephone',
          msg: 'Wrong telephone',
        });
      } else {
        this.updateNewOrder({
          charge_address: {
            ...this.newAddress,
            telephone: number.international,
          },
        });
        this.phoneNumbers.telephone = number.national;
        this.errors.remove('telephone');
      }
    },

    onInputMobile(formattedNumber, { number, isValid }) {
      if (number.input && !isValid) {
        this.errors.add({
          field: 'mobile-invalid',
          msg: 'Wrong mobile',
        });
      } else {
        this.errors.remove('mobile-invalid');
        this.updateNewOrder({
          charge_address: {
            ...this.newAddress,
            mobile: number.international,
          },
        });

        this.phoneNumbers.mobile = number.national;
      }
    },

    /* eslint-disable camelcase */
    updateAddress(e) {
      const { name } = e.target;

      this.updateNewOrder({
        charge_address: {
          ...this.newAddress,
          [name]: e.target.value,
        },
      });
    },

    /* eslint-disable camelcase */
    updateInvoice(e) {
      const { name } = e.target;

      this.updateNewOrder({
        invoice: {
          ...this.newInvoice,
          [name]: e.target.value,
        },
      });
    },

    updatePlace(place) {
      const newPlace = gMapAutocomplete.setPlace(place);

      this.updateNewOrder({
        charge_address: {
          ...this.newAddress,
          ...newPlace,
        },
      });
    },

    handleSelectVatOffice(selectedOption) {
      this.updateNewOrder({
        invoice: {
          ...this.newInvoice,
          vat_office: selectedOption,
          vat_office_id: selectedOption.id,
        },
      });
    },

    handleRemoveVatOffice() {
      this.updateNewOrder({
        invoice: {
          ...this.newInvoice,
          vat_office: null,
          vat_office_id: null,
        },
      });
    },

    updateCharges() {
      if (!isEmpty(this.newOrder.charge_address)) {
        let country;
        if (isEmpty(this.newOrder.send_address)) {
          ({ country } = this.newOrder.charge_address);
        } else {
          ({ country } = this.newOrder.send_address);
        }

        if (country) {
          country = this.$options.filters.capitalizeGreek(country);

          const isCyprus = country === 'ΚΥΠΡΟΣ';

          this.updateCountry(isCyprus);

          let send_charge;
          let checkoutCharge = this.newOrder.checkout_charge;

          if (this.isPharmacy) {
            send_charge = 0;
          } else if (this.isBoxnow) {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= 39 ? 0 : 1.5;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
            );
          }

          if (this.isPayOnDelivery) {
            checkoutCharge = isCyprus
              ? CHECKOUT_CHARGES.cyprus
              : CHECKOUT_CHARGES.greece;
          }

          this.updateNewOrder({
            send_charge,
            checkout_charge: checkoutCharge,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.radio-component,
.label {
  display: inline-block;
}

.radio-component {
  margin-right: 10px;
  vertical-align: middle;
}

.radio-control + .radio-control {
  margin-left: 1.5rem;
}

.columns__address {
  margin-bottom: 0;
}

.vue-tel-input {
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &:focus-within {
    border-color: #22a684;
    box-shadow: 0 0 0 0.125em rgba(34, 166, 132, 0.25);
    outline: none;
  }

  ::v-deep .dropdown {
    padding: 6px;

    &:focus {
      outline: none;
    }
  }

  ::v-deep ul {
    border-color: #dbdbdb;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;

    li.last-preferred {
      border-bottom-color: #dbdbdb;
    }
  }

  ::v-deep input {
    font-size: 1rem;
  }
}
</style>
